import { createStore } from 'vuex'
import toggleLoading from './modules/ztoggleLoading'
// import toggleSection from './modules/ztoggleSection'
// import createNotification from './modules/zcreateNotification'
// import crudRequest from './modules/zcrudRequest'
// import authentication from './modules/zauthentication'
// import loadRequestQueue from './modules/zloadRequestQueue'
import auth from './modules/auth'
import http from './modules/http'

export default new createStore({
    modules: {
        http,
        auth,
        toggleLoading,
        // toggleSection,
        // loadRequestQueue,
        // createNotification,
        // crudRequest,
        // authentication
    }
})
