import axios from 'axios';

const state = {};

const mutations = {};

const actions = {
  async request({ commit }, { url, method = 'GET', data = null }) {
    try {
      const response = await axios({ url, method, data });
      return response.data;
    } catch (error) {
      // Handle errors here if needed
      throw error;
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
