/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import './bootstrap';
import { createApp } from 'vue';
import store from './vuex/store';
import router from './router';
import App from './components/App.vue';
// import BootstrapVue from 'bootstrap-vue'
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import { isModalVisible, modalMessage } from './axiosConfig';

// import refreshToken from './refreshToken';
// refreshToken();


/**
 * Next, we will create a fresh Vue application instance. You may then begin
 * registering components with the application instance so they are ready
 * to use in your application's views. An example is included for you.
 */


let app = createApp(App)
//isAuth is a global javascript variable sitting at \resources\views\layouts
//deelearn:
//it looks like if blade.php isAuth need to set true, not sure why
//it looks like if id="app" need to be placed in a blade.php file,, the have to call createApp({});
// if(isAuth){
//     app = createApp({});
// }


if(isAuth){
    app = createApp({
        setup() {
            const closeModal = () => {
                isModalVisible.value = false;
                modalMessage.value = null;
                window.location.href = '/login';
            };

            const showModal = () => {
                isModalVisible.value = true;
                modalMessage.value = 'This is a demo';
            };

            const handleLogin = async () => {
              const form = document.getElementById('login-form');
              const data = new FormData(form);

              try {
                await axios.post('/login', data);
                window.location.href = '/home';
              } catch (error) {
                // The modalMessage and showErrorModal refs are already set in the interceptor
              }
            };

            return {
              showModal,
              isModalVisible,
              modalMessage,
              handleLogin,
              closeModal,
            };
          },
        // setup() {
        //     const modalVisible = ref(false);
        //     const modalMessage = ref('This is a modal message');

        //     // watch(showErrorModal, (newValue) => {
        //     //     if (newValue) {
        //     //         modalVisible.value = true;
        //     //     } else {
        //     //         modalVisible.value = false;
        //     //     }
        //     // });

        //     const showModal = () => {
        //       modalVisible.value = true;
        //     };

        //     return {
        //       modalVisible,
        //       modalMessage,
        //       showModal
        //     };
        // },
    });
}

app.use(store);
app.use(router)
// app.use(BootstrapVue)

import ExampleComponent from './components/ExampleComponent.vue';
app.component('example-component', ExampleComponent);

import ExampleTwoComponent from './components/ExampleTwoComponent.vue';
app.component('example-two-component', ExampleTwoComponent);

import LoadingComponent from './components/shared/LoadingComponent.vue';
app.component('loading-component', LoadingComponent);

import HomeComponent from './components/HomeComponent.vue';
app.component('home-component', HomeComponent);

import SingleBook from './components/SingleBook.vue';
app.component('single-book', SingleBook);

import Admin from './components/Admin.vue';
app.component('admin-component', Admin);

import TableOfContentComponent from './components/TableOfContentComponent.vue';
app.component('table-of-content-component', TableOfContentComponent);

//Layouts - starts

import HeaderComponent from './layouts/HeaderComponent.vue';
app.component('header-component', HeaderComponent);

import FooterComponent from './layouts/FooterComponent.vue';
app.component('footer-component', FooterComponent);

import NavigationComponent from './layouts/NavigationComponent.vue'; // Adjust the path as needed
app.component('navigation-component', NavigationComponent);

//Layouts - ends

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Object.entries(import.meta.glob('./**/*.vue', { eager: true })).forEach(([path, definition]) => {
    app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
});

/**
 * Finally, we will attach the application instance to a HTML element with
 * an "id" attribute of "app". This element is included with the "auth"
 * scaffolding. Otherwise, you will need to add an element yourself.
 */

app.mount('#app');
