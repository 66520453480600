import axiosInstance from '../../axiosConfig';

const state = {
  isLoggedIn: JSON.parse(localStorage.getItem('isLoggedIn')) || false,
  user: localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : null,
  user: localStorage.getItem('token') !== 'undefined' ? localStorage.getItem('token') : null,
};

const mutations = {
  SET_LOGGED_IN(state, loggedIn) {
    state.isLoggedIn = loggedIn;
    localStorage.setItem('isLoggedIn', JSON.stringify(loggedIn));
  },
  SET_USER(state, user) {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem('token', JSON.stringify(token));
  },
};

const actions = {
//   async login({ commit }, credentials) {
//     try {
//       const response = await axiosInstance.post('/auth', credentials);
//       const user = response.data.user;
//       commit('SET_LOGGED_IN', true);
//       commit('SET_USER', user);
//       return user;
//     } catch (error) {
//       throw error;
//     }
//   },

  async login({ dispatch, commit }, credentials) {
    try {
      const response = await dispatch('http/request', {
        url: '/auth',
        method: 'POST',
        data: credentials,
      }, { root: true });  // Dispatch action from root namespace
      console.log('log in success: ' + JSON.stringify(response));
      const user = response.user;
      const token = response.token;
      commit('SET_LOGGED_IN', true);
      commit('SET_USER', user);
      commit('SET_TOKEN', token);

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return user;
    } catch (error) {
      throw error;
    }
  },

  async logout({ dispatch, commit }) {
    console.log('auth/logout called')
    try {
      await dispatch('http/request', {
        url: '/out',
        method: 'GET',
      }, { root: true });  // Dispatch action from root namespace
      commit('SET_LOGGED_IN', false);
      commit('SET_USER', null);
      commit('SET_TOKEN', '');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    } catch (error) {
        console.log('auth/logout error: ', error)
        throw error;
    }
  },

};

const getters = {
  isLoggedIn: state => state.isLoggedIn,
  user: state => state.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
