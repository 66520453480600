<template>
    <div>
        <navigation-component :isSPA="true"></navigation-component>
        <!-- Masthead-->
        <header class="masthead bg-primary text-white text-center">
            <div class="container d-flex align-items-center flex-column">
                <!-- Masthead Avatar Image-->
                <!-- <img class="masthead-avatar mb-5" src="assets/img/avataaars.svg" alt="..." /> -->
                <!-- Masthead Heading-->
                <h1 class="masthead-heading text-uppercase mb-0">Deelearn</h1>
                <!-- Masthead Subheading-->
                <p class="masthead-subheading font-weight-light mb-0">Articles - Code - Resources</p>
            </div>
        </header>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-header">Test Component</div>
                        <div class="card-body">
                            test test test
                            <div>
                                <router-link to="/dashboard">Dashboard</router-link>
                            </div>
                            <div>
                                <router-link to="/demo">Demo</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Test Component mounted.')
        }
    }
</script>
