<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Example Component</div>

                    <div class="card-body">
                        I'm an example component.
                        <div>
                            <div v-if="loading">Loading...</div>
                            <div v-else>
                                <template v-if="user">
                                    <p>Name: {{ user.name }}</p>
                                    <p>Email: {{ user.email }}</p>
                                </template>
                                <template v-else>
                                    <p>No user data loaded</p>
                                </template>
                                <button @click="fetchUser">Fetch User Data</button>
                                <button @click="testNavigate">Test Navigate</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { reactive, toRefs  } from 'vue';
    import { useRouter } from 'vue-router';
    import axiosInstance from './../axiosConfig';

    export default {
        setup() {
            const router = useRouter();

            const state = reactive({
                user: null,
                loading: false,
            });

            const fetchUser = async () => {
                state.loading = true; // Set loading state
                try {
                    const response = await axiosInstance.get('/api/user');
                    state.user = response.data;
                } catch (error) {
                    console.error('Error fetching user:', error);
                    // Handle error (e.g., show error message)
                } finally {
                    state.loading = false; // Reset loading state
                }
            };

            const testNavigate = async () => {
                router.push({ name: 'welcome' });
            };

            return {
                ...toRefs(state),
                fetchUser,
                testNavigate,
            };
        },
    }
</script>
