import { createWebHistory, createRouter } from 'vue-router'
import store from './vuex/store'

// import Login from './components/pages/auth/Login'
// import Logout from './components/pages/auth/Logout'
// import Register from './components/pages/auth/Register'

import DashboardPage from './pages/DashboardPage.vue'
import TestPage from './pages/TestPage.vue'
import LogoutPage from './pages/LogoutPage.vue'
// import Profile from './components/pages/auth/Profile'

import WelcomePage from './pages/WelcomePage.vue'
import Error404 from './pages/404.vue'

const routes = [
  { path: '/', name: 'welcome', component: WelcomePage },

// LaravelPlayGround\laravel9-vue3-blog-main\resources\js\router\index.js
//   { path: '/login', component: Login, meta: { guestOnly: true } },
//   { path: '/logout', component: Logout, meta: { requiresAuth: true } },
//   { path: '/register', component: Register, meta: { guestOnly: true } },
//   { path: '/profile', component: Profile, meta: { requiresAuth: true } },

  { path: '/test', name: 'test', component: TestPage, meta: { requiresAuth: true } },
  { path: '/dashboard', name: 'dashboard', component: DashboardPage, meta: { requiresAuth: true } },
  { path: '/logout', name: 'logout', component: LogoutPage, meta: { requiresAuth: true } },
  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    component: Error404,
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})


//From https://github.com/vedmant/running-time/blob/master/resources/assets/js/router.js
// Sync Vuex and vue-router;
// sync(store, router)

// router.beforeEach((to, from, next) => {
//     console.log('router.hasRoute(to.name): ', to.name, router.hasRoute(to.name));
//     if (to.path === '/demo') {
//       window.location.href = '/';
//       return;
//     }

//     if (router.hasRoute(to.name)) {
//         next()
//     } else {
//         window.location.href = to.path;
//         return;
//     }
// });

/**
 * Authenticated routes
 */

//From https://github.com/vedmant/running-time/blob/master/resources/assets/js/router.js
// router.beforeEach(async (to, from, next) => {
//     if (! store.state.auth.me && ! store.state.auth.authChecked) {
//       await store.dispatch('checkLogin')
//         .catch(() => {
//         })
//     }
//     const me = store.state.auth.me

//     if (to.matched.some(record => record.meta.guestOnly) && me) {
//       // Guest only page, dont follow there when user is authenticated
//       next(false)
//     } else if (to.matched.some(record => record.meta.requiresAuth) && ! me) {
//       // if route requires auth and user isn't authenticated
//       next('/login')
//     } else if (to.matched.some(record => record.meta.requiresAdmin) &&
//       (! me || ! ['admin', 'manager'].includes(store.state.auth.me.role))) {
//       // if route required admin or manager role
//       next('/login')
//     } else {
//       next()
//     }
//   })

//From ChatGPT - starts
// router.beforeEach((to, from, next) => {
//     if (to.meta.requiresAuth && !store.getters['auth/isLoggedIn']) {
//       next('/login'); // Redirect to login if not authenticated
//     } else {
//       next(); // Proceed to the route
//     }
// });
//From ChatGPT - ends

export default router
