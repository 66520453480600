<template>
   <div class="modal fade" id="regisModal" tabindex="-1" aria-labelledby="regisModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="regisTitle">Registration</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
                <div class="modal-body">
                     <div class="mb-3">
                        <label for="regisLogin" class="form-label">Login User (Name)</label>
                        <input v-model="name" type="email" class="form-control" id="regisLogin" aria-describedby="regisLogin">
                        <div class="form-text">The name should be unique 6-15 characters.</div>
                    </div>
                    <div class="mb-3">
                        <label for="regisEmail" class="form-label">Email Address</label>
                        <input v-model="email" type="email" class="form-control" id="regisEmail" aria-describedby="regisEmail">
                        <div class="form-text">Please note. We will never give your email to anyone else.</div>
                    </div>
                    <div class="mb-3">
                        <label for="regisPassword" class="form-label">Password</label>
                        <input v-model="password" type="password" class="form-control" id="regisPassword">
                         <div class="form-text">The password should be 8-20 characters.</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click.prevent="createUser" type="submit" class="btn btn-primary">Register</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: "ModalAuth",

    data() {
        return {
            name: null,
            email: null,
            password: null,
        }
    },
    props: [
        'book_info',
    ],
    mounted() {
        this.update()
    },
    methods: {
        update: function () {
            console.log('Hello')
        },
        createUser() {
            axios.post('/registration', {
                name: this.name,
                email: this.email,
                password: this.password,
            }).then(res => {
                if (res.status === 200) {
                    location.replace('/')
                }
            })
        }
    }
}
</script>
<style>

</style>
