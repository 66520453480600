<template>
    <div class="info d-flex justify-content-between m-4 mt-4 mb-5 border-top">
        <h5 class="info__title">Footer</h5>
        <p class="info__title">{{ currentYear }}</p>
    </div>
</template>

<script>
export default {
    name: "FooterComponent",
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    }
}
</script>

<style scoped>

</style>
