<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Example Component 2</div>

                    <div class="card-body">
                        <div class="row">
                            <loading-component style="height: 20px; top: 0;" key="1" color="success" v-show="$store.getters.isLoading(section)"></loading-component>
                        </div>
                        <div class="row">
                            <div class="col-auto">
                                <div class="btn btn-sm btn-success btn-block b-rad-none" @click="clickMe();">is Loading</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data(){
            return {
                section: 'exampleTwo',
            }
        },
        mounted() {
            this.$store.dispatch('toggleLoading', {name: this.section, status: true});
            console.log('Component mounted.');
        },
        methods: {
            clickMe(){
                this.$store.dispatch('toggleLoading', {name: this.section, status: false});
            },
        }
    }
</script>
