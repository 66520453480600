<template>
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
      <div class="container">
        <template v-if="isSPA">
            <router-link to="/" class="navbar-brand">{{ appName }}</router-link>
        </template>
        <template v-else>
            <a href="/" class="navbar-brand">{{ appName }}</a>
        </template>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarResponsive">
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav me-auto"></ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ms-auto">
            <li class="nav-item mx-0 mx-lg-1">
              <a class="nav-link py-3 px-0 px-lg-3 rounded" href="#portfolio">Portfolio</a>
            </li>
            <li class="nav-item mx-0 mx-lg-1">
              <a class="nav-link py-3 px-0 px-lg-3 rounded" href="#contact">Contact</a>
            </li>
            <!-- Authentication Links -->
            <!-- <template v-if="!isAuthenticated">
              <li class="nav-item" v-if="routes.login">
                <a class="nav-link py-3 px-0 px-lg-3 rounded" :href="routes.login">Login</a>
              </li>
              <li class="nav-item" v-if="routes.register">
                <a class="nav-link py-3 px-0 px-lg-3 rounded" :href="routes.register">Register</a>
              </li>
            </template>
            <template v-else>
            -->
            <template>
              <li class="nav-item dropdown">
                <a
                  id="navbarDropdown"
                  class="nav-link py-3 px-0 px-lg-3 dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ userName }}
                </a>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <a
                    class="dropdown-item"
                    href="#"
                    @click="logout"
                  >
                    Logout
                  </a>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </nav>
  </template>

  <script>
  import { ref, onMounted, computed, getCurrentInstance, defineComponent } from 'vue';
  import 'bootstrap/dist/js/bootstrap.bundle.min.js';

  export default defineComponent({
    name: 'Navbar',
    props: {
        isSPA: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
      const appName = 'Deelearn';
      const isAuthenticated = ref(false); // Set this based on your authentication logic
      const userName = ref(''); // Replace with logic to get the authenticated user's name
      const routes = {
        login: '/login', // Replace with your login route
        register: '/register', // Replace with your register route
      };

      const logout = () => {
        // Implement your logout logic here
        console.log('Logout clicked');
      };

      onMounted(() => {
        const mainNav = document.getElementById('mainNav');
        if (mainNav) {
            new bootstrap.ScrollSpy(document.body, {
            target: '#mainNav',
            rootMargin: '0px 0px -40%',
            });
        }

        // this.$nextTick(() => {
        //     this.$bootstrap.Scrollspy(document.body,  {
        //         target: '#mainNav',
        //         rootMargin: '0px 0px -40%',
        //     });
        // });
      });

      console.log('isSPA', props.isSPA);

      return {
        appName,
        isAuthenticated,
        userName,
        routes,
        isSPA: props.isSPA,
        logout,
      };
    },
  });
  </script>

  <style scoped>
  /* Your custom styles */
  </style>
