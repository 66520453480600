<template>
    <div>
        <navigation-component :isSPA="true"></navigation-component>
           <!-- Masthead-->
           <header class="masthead bg-primary text-white text-center">
            <div class="container d-flex align-items-center flex-column">
                <!-- Masthead Avatar Image-->
                <!-- <img class="masthead-avatar mb-5" src="assets/img/avataaars.svg" alt="..." /> -->
                <!-- Masthead Heading-->
                <h1 class="masthead-heading text-uppercase mb-0">Logging Out</h1>
            </div>
        </header>
    </div>
  </template>

  <script>
  import { onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import axios from 'axios';

  export default {
    name: 'LogoutPage',
    setup() {
      const store = useStore();
      const router = useRouter();

      const performLogout = async () => {
        try {
            // await axios.post('/logout');
            await store.dispatch('auth/logout');

            //redirect to /login page
            window.location.href = '/login';

            //redirect to / page (method 1)
            // router.push({ name: 'welcome' });

            //redirect to / page (method 2)
            // axios.get('/out').then(res => {
            //     res.status === 200 ? location.replace('/') : false
            // })

        } catch (error) {
          console.error('Error logging out:', error);
          // Handle the error appropriately
        }
      };

      onMounted(() => {
        performLogout();
      });

      return {};
    }
  };
  </script>
