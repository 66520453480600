<template>
   <div class="modal fade" id="editBookModal" tabindex="-1" aria-labelledby="editBookModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="titleEditBookModal">Edit an existing book</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="editBookChoiceAuthor" class="form-label">Select the author</label>
                        <select v-model="author_id" class="form-select" aria-label="editBookChoiceAuthor">
                            <option v-for=" author in authors" :value ="author.id">{{author.fullname}}</option>
                        </select>
                        <div class="form-text">Compulsory field</div>
                    </div>
                    <div class="mb-3">
                        <label for="editBookChoiceCategory" class="form-label">Select the genre</label>
                        <select v-model="category_id" class="form-select" aria-label="editBookChoiceCategory">
                           <option v-for=" category in categories" :value ="category.id">{{category.title}}</option>
                        </select>
                        <div class="form-text">Compulsory field</div>
                    </div>
                    <div class="mb-3">
                        <label for="editBookChoiceName" class="form-label">Title of the book</label>
                        <input v-model="title" type="text" class="form-control" id="editBookChoiceName" aria-describedby="editBookChoiceName">
                        <div class="form-text">The name must be unique</div>
                    </div>
                    <div class="mb-3">
                        <label for="editBookChoiceYear" class="form-label">Year of publication</label>
                        <input v-model="year" type="text" class="form-control" id="editBookChoiceYear" aria-describedby="editBookChoiceYear">
                        <div class="form-text">In format from the Nativity of Christ (no more than 4 digits)</div>
                    </div>
                    <div class="mb-3">
                        <label for="editBookChoiceDescription" class="form-label">Description</label>
                        <textarea  v-model="description" class="form-control" id="editBookChoiceDescription" rows="3"></textarea>
                        <div class="form-text">Detailed description, no more than 2000 characters</div>
                    </div>
                    <div class="mb-3">
                        <p class="fs-5 d-flex justify-content-center mt-2" :class="{'text-danger': hasError, 'text-success': noError}">{{message}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click.prevent="updateBook" type="submit" class="btn btn-primary">Edit</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ChangeBook",

    data() {
        return {
            author_id: this.defaultIdAuthor,
            category_id: this.defaultIdCategory,
            title: this.defaultTitle,
            year: this.defaultYear,
            description: this.defaultDescription,
            message: '',
            noError: true,
            hasError: false,
        }
    },
    props: [
        'authors',
        'categories',
        'id',
        'defaultIdAuthor',
        'defaultIdCategory',
        'defaultAuthor',
        'defaultCategory',
        'defaultTitle',
        'defaultYear',
        'defaultDescription',
    ],
    mounted() {
        this.update()
        console.log(this.defaultDescription);
    },
    methods: {
        update: function () {
        },
        updateBook() {
            axios.post('/update', {
                id: this.id,
                title: this.title,
                year: Number(this.year),
                description: this.description,
                author_id: Number(this.author_id),
                category_id: Number(this.category_id)
            }).then(res => {
                if (res.status === 200) {
                    this.message = 'The book successfully edited'
                }
            }).catch(error => {
                if (error.response) {
                    this.noError = false
                    this.hasError = true
                    this.message = error.response.data.message;
                    console.log(error.response.data.message);
                }
                else {
                    this.noError = false
                    this.hasError = true
                    this.message = 'Server side error';
                }
            })
        },
    }
}
</script>
<style>

</style>
