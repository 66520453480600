<template>
   <div class="modal fade" id="deleteCategoryModal" tabindex="-1" aria-labelledby="deleteCategoryModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="deleteCategoryTitle">Delete category</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
                <div class="modal-body">
                     <div class="mb-3">
                        <label for="deleteCategoryChoiceCategory" class="form-label">Select a category</label>
                        <select v-model="id" class="form-select" aria-label="Default select example">
                            <option v-for=" category in categories" :value ="category.id">{{category.title}}</option>
                        </select>
                        <div class="form-text">Compulsory field</div>
                    </div>
                    <div class="mb-3">
                        <p class="fs-5 d-flex justify-content-center mt-2" :class="{'text-danger': hasError, 'text-success': noError}">{{message}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click.prevent="deleteCategory" type="submit" class="btn btn-danger">Delete</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "DeleteCategory",

    data() {
        return {
            id: null,
            message: '',
            noError: true,
            hasError: false,
        }
    },
    props: [
        'categories',
    ],
    mounted() {
        this.update()
    },
    methods: {
        update: function () {
        },
        deleteCategory() {
            axios.post('/administration/deleteCategory', {
                id: Number(this.id),
            }).then(res => {
                if (res.status === 200) {
                    this.message = 'You have successfully deleted the category'
                }
            }).catch(error => {
                if (error.response) {
                    this.noError = false
                    this.hasError = true
                    this.message = error.response.data.message;
                    console.log(error.response.data.message);
                }
                else {
                    this.noError = false
                    this.hasError = true
                    this.message = 'Server side error';
                }
            })
        },
    }
}
</script>
<style>

</style>
