<template>
    <div class="modal fade" id="authOutModal" tabindex="-1" aria-labelledby="authOutModal" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="authOutTitle">Logout</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">No</button>
                <button @click="logOut()" type="submit" class="btn btn-danger">Yes</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: "ModalAuthOut",

    props: [
        'book_info',
    ],
    mounted() {
        this.update()
    },
    methods: {
        update: function () {
            console.log('Hello')
        },
        logOut() {
            axios.get('/out').then(res => {
                res.status === 200 ? location.replace('/') : false
            })
        }
    }
}
</script>
<style>

</style>
