import axios from 'axios';
import { ref } from 'vue';

const axiosInstance = axios.create({
    //baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost:8000/api',
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const modalMessage = ref(null);
export const isModalVisible = ref(null);

axiosInstance.interceptors.response.use(
    response => {
        console.log('Response:', response);
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            // throw 'Not Authenticated';
            console.error('Not authenticated');
            isModalVisible.value = true;
            modalMessage.value = 'Unauthorized access. Please log in.';
            setTimeout(() => {
                modalMessage.value = null;
                isModalVisible.value = false;
            }, 3000);
            // router.push({ name: 'Login' });
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
