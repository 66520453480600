<template>
   <div class="modal fade" id="authModal" tabindex="-1" aria-labelledby="authModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="authModalTitle">Authorization</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
                <div class="modal-body" v-if="!loading">
                    <div class="mb-3">
                        <label for="authModalEmail" class="form-label">Email Address</label>
                        <input v-model="email" type="email" class="form-control" id="authModalEmail" aria-describedby="authModalEmail">
                        <div class="form-text">Enter your email</div>
                    </div>
                    <div class="mb-3">
                        <label for="authModalPassword" class="form-label">Password</label>
                        <input v-model="password" type="password" class="form-control" id="authModalPassword">
                         <div class="form-text">Enter your password</div>
                    </div>
                    <div class="mb-3 form-check">
                        <input type="checkbox" class="form-check-input" id="authModalRememberMe">
                        <label class="form-check-label" for="authModalRememberMe">Remember me</label>
                    </div>
                </div>
                <div class="modal-footer" v-if="!loading">
                    <button @click.prevent="defineUser" type="submit" class="btn btn-primary">Login</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
                <div v-else class="d-flex justify-content-center align-items-center p-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ModalAuth',

  props: {
    book_info: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const email = ref(null);
    const password = ref(null);
    const loading = ref(false);
    const store = useStore();

    const update = () => {
      console.log('Hello');
    };

    onMounted(() => {
      update();
    });

    const defineUser = async () => {
      loading.value = true;
      try {
        console.log('Log in attempt as:', email.value);
        const credentials = { email: email.value, password: password.value };
        await store.dispatch('auth/login', credentials);
        // Optionally, redirect to another page or perform additional actions after login

        window.location.href = '/admin';

      } catch (error) {
        loading.value = false;
        console.log('Login failed:', error);
      } finally {
        // loading.value = false;
      }
    };

    return {
      email,
      password,
      loading,
      update,
      defineUser
    };
  }
};

// export default {
//     name: "ModalAuth",
//     data() {
//         return {
//             email: null,
//             password: null,
//         }
//     },
//     props: [
//         'book_info',
//     ],
//     mounted() {
//         this.update()
//     },
//     methods: {
//         update: function () {
//             console.log('Hello')
//         },
//         async defineUser() {
//             try {
//                 console.log('Log in attempt as:', this.email);
//                 const credentials = { email: this.email, password: this.password };
//                 await this.$store.dispatch('auth/login', credentials);
//                 // Optionally, redirect to another page or perform additional actions after login
//                 this.$router.push('/dashboard');
//             } catch (error) {
//                 console.log('Login failed:', error);
//             }
//         },
//         // defineUser() {
//         //     console.log('Logged in as:', email);
//         //     axios.post('/auth', {
//         //         email: this.email,
//         //         password: this.password,
//         //     }).then(res => {
//         //         if (res.status === 200) {
//         //             location.replace('/');
//         //         }
//         //     })
//         // },
//     }
// }
</script>
<style>

</style>
