<template>
   <div id="mySidebar" class="sidebar">
        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">×</a>
        <div v-html="data"></div>
    </div>
</template>

<script>
    export default {
        props: {
            data:{
                type: String,
                required: true
            }
        },
        mounted() {
            console.log('TableOfContent Component mounted.')
        }
    }
</script>
