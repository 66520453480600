<template>
    <!-- <div class="container px-4" id="custom-cards"> -->
    <div>
        <navigation-component :isSPA="true"></navigation-component>
        <!-- Masthead-->
        <header class="masthead bg-primary text-white text-center">
            <div class="container d-flex align-items-center flex-column">
                <!-- Masthead Avatar Image-->
                <!-- <img class="masthead-avatar mb-5" src="assets/img/avataaars.svg" alt="..." /> -->
                <!-- Masthead Heading-->
                <h1 class="masthead-heading text-uppercase mb-0">Deelearn</h1>
                <!-- Masthead Subheading-->
                <p class="masthead-subheading font-weight-light mb-0">Articles - Code - Resources</p>
            </div>
        </header>
        <!-- Portfolio Section-->
        <section class="portfolio" id="portfolio">
            <div class="container">
                <!-- Portfolio Section Heading-->
                <!-- <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Main Menu</h2> -->
                <!-- Icon Divider-->
                <!-- <div class="divider-custom">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                    <div class="divider-custom-line"></div>
                </div> -->
                <!-- Portfolio Grid Items-->
                <div class="row justify-content-center">
                    <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">


                        <div class="col">
                            <div class="card card-cover h-100 overflow-hidden rounded-5">
                            <div class="d-flex flex-column h-100 p-5 pb-5 text-shadow-1">
                                <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold"><a href="/blog">Blog</a></h2>
                            </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-cover h-100 overflow-hidden rounded-5">
                            <div class="d-flex flex-column h-100 p-5 pb-5 text-shadow-1">
                                <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold"><a href="/catalog">Catalog</a></h2>
                            </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-cover h-100 overflow-hidden rounded-5">
                            <div class="d-flex flex-column h-100 p-5 pb-5 text-shadow-1">
                                <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold"><a href="/articles">Articles</a></h2>
                            </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-cover h-100 overflow-hidden rounded-5">
                            <div class="d-flex flex-column h-100 p-5 pb-5 text-shadow-1">
                                <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold text-black"><router-link to="/dashboard">Dashboard</router-link></h2>
                            </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-cover h-100 overflow-hidden rounded-5">
                            <div class="d-flex flex-column h-100 p-5 pb-5 text-shadow-1">
                                <!-- <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold"><a href="/test">Test</a></h2> -->
                                <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold"><router-link to="/test">Test</router-link></h2>
                            </div>
                            </div>
                        </div>

                        <!-- Original laravel blade page -->
                        <!-- <div class="col">
                            <div class="card card-cover h-100 overflow-hidden rounded-5">
                            <div class="d-flex flex-column h-100 p-5 pb-5 text-shadow-1">
                                <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold"><a href="/welcome">Welcome</a></h2>
                            </div>
                            </div>
                        </div> -->

                        <!-- <div class="col">
                            <div class="card card-cover h-100 overflow-hidden rounded-5" style="background-image: url('unsplash-photo-3.jpg');">
                            <div class="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                <h2 class="pt-2 mt-2 mb-4 lh-1 fw-bold"><a href="/catalog">Catalog</a></h2>
                                <ul class="d-flex list-unstyled mt-auto">
                                <li class="me-auto">
                                    <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" class="rounded-circle border border-white">
                                </li>
                                <li class="d-flex align-items-center me-3">
                                    <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#geo-fill"/></svg>
                                    <small>California</small>
                                </li>
                                <li class="d-flex align-items-center">
                                    <svg class="bi me-2" width="1em" height="1em"><use xlink:href="#calendar3"/></svg>
                                    <small>5d</small>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact Section-->
        <section class="page-section" id="contact">
            <div class="container">
                <!-- Contact Section Heading-->
                <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Contact Me</h2>
                <!-- Icon Divider-->
                <!-- <div class="divider-custom">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                    <div class="divider-custom-line"></div>
                </div> -->
                <!-- Contact Section Form-->
                <contact-form></contact-form>
            </div>
        </section>
    </div>
</template>

<script>
import ContactForm from '../components/ContactForm.vue';

    export default {
        mounted() {
            console.log('Welcome Component mounted.')
        }
    }
</script>
