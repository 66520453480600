<!-- ContactForm.vue -->
<template>
    <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-7">
            <form @submit.prevent="submitForm" id="contactForm">
                <!-- Name input-->
                <div class="form-floating mb-3">
                    <input v-model="formData.name" class="form-control" type="text" placeholder="Enter your name..." required />
                    <label>Full name</label>
                </div>
                <!-- Email address input-->
                <div class="form-floating mb-3">
                    <input v-model="formData.email" class="form-control" type="email" placeholder="name@example.com" required />
                    <label>Email address</label>
                </div>
                <!-- Phone number input-->
                <div class="form-floating mb-3">
                    <input v-model="formData.phone" class="form-control" type="tel" placeholder="(123) 456-7890" />
                    <label>Phone number (optional)</label>
                </div>
                <!-- Message input-->
                <div class="form-floating mb-3">
                    <textarea v-model="formData.message" class="form-control" placeholder="Enter your message here..." style="height: 10rem" required></textarea>
                    <label>Message</label>
                </div>
                <!-- Submit Button-->
                <button :disabled="isSubmitting" class="btn btn-primary btn-xl" type="submit">Send</button>
            </form>
            <div v-if="submissionMessage" class="mt-3 alert alert-success d-flex align-items-center">
                <span class="flex-grow-1">{{ submissionMessage }}</span>
                <button type="button" class="btn btn-success ms-2" @click="dismissMessage('submissionMessage')">OK</button>
            </div>
            <div v-if="submissionError" class="mt-3 alert alert-danger d-flex align-items-center">
                <span class="flex-grow-1">{{ submissionError }}</span>
                <button type="button" class="btn btn-danger ms-2" @click="dismissMessage('submissionError')">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
// export default {
//     data() {
//         return {
//             formData: {
//                 name: '',
//                 email: '',
//                 phone: '',
//                 message: ''
//             },
//             isSubmitting: false,
//             submissionMessage: '',
//             submissionError: ''
//         };
//     },
//     methods: {
//         async submitForm() {
//             this.isSubmitting = true;
//             try {
//                 const response = await fetch('/api/contact', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(this.formData),
//                 });
//                 const responseData = await response.json();
//                 if (response.ok) {
//                     this.submissionMessage = responseData.message;
//                 } else {
//                     throw new Error(responseData.error || 'Failed to submit form');
//                 }
//             } catch (error) {
//                 this.submissionError = error.message;
//             } finally {
//                 this.isSubmitting = false;
//             }
//         }
//     }
// };

import { ref } from 'vue';

export default {
    setup() {
        const formData = ref({
            name: '',
            email: '',
            phone: '',
            message: ''
        });
        const isSubmitting = ref(false);
        const submissionMessage = ref('');
        const submissionError = ref('');

        const submitForm = async () => {
            isSubmitting.value = true;
            submissionMessage.value = '';
            submissionError.value = '';
            try {
                const response = await fetch('/api/contact', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData.value),
                });
                const responseData = await response.json();
                if (response.ok) {
                    submissionMessage.value = responseData.message;
                    resetForm();
                } else {
                    throw new Error(responseData.error || 'Failed to submit form');
                }
            } catch (error) {
                submissionError.value = error.message;
            } finally {
                isSubmitting.value = false;
            }
        };

        const resetForm = () => {
            formData.value.name = '';
            formData.value.email = '';
            formData.value.phone = '';
            formData.value.message = '';
        };

        const dismissMessage = (messageType) => {
            if (messageType === 'submissionMessage') {
                submissionMessage.value = '';
            } else if (messageType === 'submissionError') {
                submissionError.value = '';
            }
        };

        return {
            formData,
            isSubmitting,
            submissionMessage,
            submissionError,
            submitForm,
            resetForm,
            dismissMessage
        };
    }
};
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
