<template>
    <div class="modal fade" id="messageModal" tabindex="-1" aria-labelledby="messageModal" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <p>{{ message }}</p>
                <button @click="closeModal">Close</button>
            </div>
         </div>
     </div>
 </template>

  <script>
  export default {
    props: {
      message: String,
    },
    setup() {
    },
  };
  </script>
