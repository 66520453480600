<template>
    <div class="modal" @click="handleModalClick">
      <div class="modal-content">
        <p>{{ message }}</p>
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </template>

  <script>
  import { ref } from 'vue';

  export default {
    props: {
      message: String,
    },
    setup(props, { emit }) {
      const closeModal = () => {
        console.log('closeModal');
        emit('close');
      };

      const handleModalClick = (event) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
      };

      return {
        closeModal,
        handleModalClick
      };
    },
  };
  </script>

  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  </style>
