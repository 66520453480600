<template>
   <div class="modal fade" id="addAuthorModal" tabindex="-1" aria-labelledby="addAuthorModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="addAuthorTitle">Add an Author</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="addAuthorName" class="form-label">Name of the Author</label>
                        <input v-model="fullname" type="text" class="form-control" id="addAuthorName" aria-describedby="addAuthorName">
                        <div class="form-text">Full name should be unique.</div>
                    </div>
                    <div class="mb-3">
                        <label for="addAuthorCountry" class="form-label">Country of birth</label>
                        <input v-model="country" type="text" class="form-control" id="addAuthorCountry" aria-describedby="addAuthorCountry">
                        <div class="form-text">Compulsory field</div>
                    </div>
                    <div class="mb-3">
                        <label for="addAuthorComment" class="form-label">Comment</label>
                        <textarea  v-model="comment" class="form-control" id="addAuthorComment" rows="3"></textarea>
                        <div class="form-text">Comment, optional</div>
                    </div>
                    <div class="mb-3">
                         <p class="fs-5 d-flex justify-content-center mt-2" :class="{'text-danger': hasError, 'text-success': noError}">{{message}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click.prevent="createAuthor" type="submit" class="btn btn-primary">Add</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AddAuthor",

    data() {
        return {
            fullname: null,
            country: null,
            comment: null,
            message: '',
            noError: true,
            hasError: false,
        }
    },
    props: [
    ],
    mounted() {
        this.update()
    },
    methods: {
        update: function () {
        },
        createAuthor() {
            axios.post('/administration/addAuthor', {
                fullname: this.fullname,
                country: this.country,
                comment: this.comment,
            }).then(res => {
                if (res.status === 200) {
                    this.message = 'You have successfully added the author'
                }
            }).catch(error => {
                if (error.response) {
                    this.noError = false
                    this.hasError = true
                    this.message = error.response.data.message;
                    console.log(error.response.data.message);
                }
                else {
                    this.noError = false
                    this.hasError = true
                    this.message = 'Server side error';
                }
            })
        },
    }
}
</script>
<style>

</style>
