<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <h3>Error 404</h3>
                <hr>

                Page not found.
            </div>
        </div>
    </div>
  </template>

  <script>
  export default {

    data () {
      return {}
    },

    methods: {},
  }
  </script>
